import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  notFoundArea: {
    textAlign: "center",
  },
  pageEnd: {
    height: "100px",
  },
}))
