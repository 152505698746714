import React from "react"

import { Grid, Box, Typography } from "@material-ui/core"
import { MuiThemeProvider } from "@material-ui/core/styles"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Theme } from "../components/style/theme"
import { useStyles } from "../components/style/notFoundStyle"

const NotFoundPage = () => {
  const classes = useStyles()

  return (
    <MuiThemeProvider theme={Theme}>
      <Layout page={"404"}>
        <SEO title="404: Not found" />
        <Grid container justify="center">
          <Grid item xs={12}>
            <Box className={classes.notFoundArea}>
              <Typography variant="h4">404: Not Found</Typography>
              <Typography variant="body1">ページが存在しません</Typography>
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.pageEnd} />
      </Layout>
    </MuiThemeProvider>
  )
}

export default NotFoundPage
